<template>
  <relatorio :headers="headersRel" />
</template>
<script>
  import Relatorio from './Relatorio.vue'
  import { sortCurrency } from '../../utils/sorts'

  export default {
    components: {
      Relatorio,
    },
    data () {
      return {
      }
    },
    computed: {
      headersRel () {
        return [
          { align: 'left', sortable: true, text: 'Técnico', value: 'tecnico', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, text: 'Período', value: 'periodo', width: 'auto', report: true, styleReport: { halign: 'center' } },
          { align: 'right', sortable: true, text: 'Pedidos Faturados', value: 'pedidos_faturados', width: 'auto', report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Evolução Pedidos (%)', value: 'evolucao_pedidos', width: 'auto', sort: this.valueOrder, report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Valor Total (R$)', value: 'valor_total', width: 'auto', sort: sortCurrency, report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Evolução Faturamento (%)', value: 'evolucao_faturamento', width: 'auto', sort: this.valueOrder, report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Produtores', value: 'produtores', width: 'auto', report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Evolução Produtores (%)', value: 'evolucao_produtores', width: 'auto', sort: this.valueOrder, report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Média Período (R$)', value: 'media_periodo', width: 'auto', sort: sortCurrency, report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Ticket Médio por Pedido (R$)', value: 'ticket_medio_pedido', width: 'auto', sort: sortCurrency, report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Ticket Médio por Produtor (R$)', value: 'ticket_medio_produtores', width: 'auto', sort: sortCurrency, report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Dias', value: 'dias', width: 'auto', report: true, styleReport: { halign: 'right' } },
        ]
      },
      valueOrder () {
        return (a, b) => {
          const aSemPonto = a.replace(/\./g, '')
          const bSemPonto = b.replace(/\./g, '')
          const aComPonto = aSemPonto.replace(',', '.')
          const bComPonto = bSemPonto.replace(',', '.')
          return parseFloat(aComPonto) - parseFloat(bComPonto)
        }
      },
    },
  }
</script>
